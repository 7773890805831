import React, { ReactElement } from 'react'
import Helmet from 'react-helmet'
import Favicon from '../../assets/images/favicon.png'

type Props = {
  defaultTitle?: string
  seoTitle?: string
  seoDescription?: string
  title?: string
}

const SEO = ({ seoTitle, defaultTitle, seoDescription, title }: Props): ReactElement => {
  return (
    <>
      <Helmet defer={false} defaultTitle={defaultTitle}>
        <html lang="en" />
        <link rel="canonical" href="" />
        <link rel="icon" href={Favicon} />
        <meta name="docsearch:version" content="2.0" />
        <meta name="theme-color" content="#157b58" />
        <title>{seoTitle ? seoTitle : title}</title>
        <meta name="description" content={seoDescription} />
        <meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=1,user-scalable=0" />
        <link
          rel="stylesheet"
          href="https://maxst.icons8.com/vue-static/landings/line-awesome/line-awesome/1.3.0/css/line-awesome.min.css"
        />
      </Helmet>
      <script type="text/javascript" id="snapEngage" />
    </>
  )
}

export default SEO
