export function splitToChunks(array: any, parts: number) {
  let result = []
  for (let i = parts; i > 0; i--) {
    result.push(array.splice(0, Math.ceil(array.length / i)))
  }
  return result
}

export function isEmail(email: string) {
  // eslint-disable-next-line
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const ellipsis = (text: any, limit: number) => {
  if (text) {
    return text.length > limit ? `${text.substring(0, limit)}...` : text
  }
}

export const isContact = (data: string) => {
  if (/[a-zA-Z]/.test(data)) {
    return false
  } else {
    if (data.length < 16 && data.length >= 7) {
      return true
    } else {
      return false
    }
  }
}

export const capitalizeWords = (value: string) => {
  return value.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export function stringToHTML(str: string) {
  var parser = new DOMParser()
  var doc = parser.parseFromString(str, 'text/html')
  return doc.body
}

// Site Version, change for every suitable release.
export const siteVersion = '0.2.7'
